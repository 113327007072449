input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #8a8a8a;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #8a8a8a;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #8a8a8a;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #8a8a8a;
}

.error-input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #b21414;
}
.error-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b21414;
}
.error-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b21414;
}
.error-input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #b21414;
}
