@keyframes slide {
  from {
    left: 100%;
  }
  to {
    left: -100%;
  }
}
@-webkit-keyframes slide {
  from {
    left: 100%;
  }
  to {
    left: -100%;
  }
}

@keyframes slide-mobile {
  from {
    left: 100%;
  }
  to {
    left: -125%;
  }
}
@-webkit-keyframes slide-mobile {
  from {
    left: 100%;
  }
  to {
    left: -125%;
  }
}

.text-wrapper {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: calc((100% - 53rem) / 2);
  min-width: 100%;
}

.text-wrapper-mobile {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  animation-name: slide-mobile;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: slide-mobile;
  -webkit-animation-duration: 15s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
