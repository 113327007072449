@import url(//fonts.googleapis.com/css?family=Paytone+One);
@import url(//fonts.googleapis.com/css?family=Lato);

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
}

div::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #969696;
}

div::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

body::-webkit-scrollbar {
  width: 7px;
  display: none;
}

div::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
  border: 3px solid #f1f1f1;
}

input {
  border: none;
  outline: none;
  box-sizing: border-box;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

@keyframes flyInAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
