.email-field:focus::placeholder {
  color: transparent;
}

.email-field::-webkit-input-placeholder {
  /* WebKit browsers */
  font-family: "Paytone One";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 120%;
  color: #3c3c3c;
}
.email-field:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-family: "Paytone One";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 120%;
  color: #3c3c3c;
}
.email-field::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-family: "Paytone One";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 120%;
  color: #3c3c3c;
}
.email-field:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-family: "Paytone One";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 120%;
  color: #3c3c3c;
}
